import 'reflect-metadata';
import { createApp } from 'vue';
import App from '@/App.vue';
import { initDependencies } from '@/bootstrap';
import { Identifier } from '@/directives/Identifier';

import '@bookingkit/coppi/dist/style.css';
import '@/css/default-override.css';
import { CoppiLocalisationPlugin, CoppiErrorReportingPlugin } from '@bookingkit/coppi';
import { applicationContainer } from '@/bootstrap/applicationServiceProvider';
import {
    AUTHENTICATION_SERVICE,
    LOGGER_SERVICE,
    USER_SERVICE,
    SUPPLIER_SERVICE,
} from '@/bootstrap/ServiceProviders';
import { LoggerServiceInterface } from '@/models/Services/LoggerServiceInterface';
import { NODE_ENV, SENTRY_DNS, SENTRY_TUNNEL } from '@/bootstrap/environment';
import { UserServiceInterface } from '@/models/UserServiceInterface';
import useUser from '@/state/user';
import posthogPlugin from '@/plugins/Posthog';
import { useGtmService } from '@/services/GtmService';
import { useUserSupplier } from '@/composables/useSupplier/useUserSupplier';
import { SupplierServiceInterface } from '@/models/Services/SupplierServiceInterface';
import { setRouteFromLegacyApp, setSupplierFromUrl } from '@/bootstrap/urlParams';
import internationalization, { changeLocale } from './i18nSetup';

const initApplication = async () => {
    const authService = applicationContainer
        .get<AuthenticationServiceInterface>(AUTHENTICATION_SERVICE);
    const loggerService = applicationContainer
        .get<LoggerServiceInterface>(LOGGER_SERVICE);
    const gtmService = useGtmService();

    try {
        await authService.init();
        const { default: router } = await import('@/router');
        const { NextRoutingPlugin, useRoutingService } = await import('@/plugins/NextRouting');

        await initDependencies();
        const BookingKitApplication = createApp(App);
        BookingKitApplication.use(CoppiLocalisationPlugin, {});
        BookingKitApplication.use(NextRoutingPlugin, router);
        BookingKitApplication.use(CoppiErrorReportingPlugin, { logException: loggerService.captureException });
        try {
            BookingKitApplication.use(gtmService, {
                id: 'INVALID',
                vueRouter: router,
            });
        } catch (e) {
            loggerService.captureException(e);
        }
        loggerService.bindToApplication({
            application: BookingKitApplication,
            router,
            environment: NODE_ENV,
            dsn: SENTRY_DNS,
            dist: 'spa',
            release: APP_VERSION_STATIC,
            tunnel: SENTRY_TUNNEL,
        });

        BookingKitApplication.directive('identifier', Identifier);

        const { setUser, getUserLocale } = useUser();
        const userDataService = applicationContainer
            .get<UserServiceInterface>(USER_SERVICE);
        const userData = await userDataService
            .getCurrentUserFromRemote();
        const supplierService = applicationContainer.get<SupplierServiceInterface>(SUPPLIER_SERVICE);

        setUser(userData);
        loggerService.setUser(userData);
        changeLocale(getUserLocale());

        await setSupplierFromUrl({
            useUserSupplier: useUserSupplier({ supplierService, loggerService }),
            loggerService,
        });

        BookingKitApplication
            .use(internationalization)
            .use(router)
            .use(posthogPlugin)
            .mount('#coppi-app');
        setRouteFromLegacyApp({ routerService: useRoutingService() });
    } catch (e) {
        loggerService.captureException(e);
    }
};

initApplication()
    .catch((e) => console.error('Something when terribly wrong. Please contact tech@bookingkit.com', e));
